.Typeahead {
    input {
        cursor: pointer;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 16px 12px;

        &:disabled {
            cursor: default;
            background-color: $white;
            -webkit-box-shadow: 0 0 0 0 $white inset !important;
            box-shadow: 0 0 0 0 $white inset !important;
        }
    }

    .rbt-input-hint {
        background: none;
        display: none;
    }

    .rbt-menu {
        width: auto !important;
    }

    .toggle-button {
        background: transparent;
        border: 0;
        position: absolute;
        right: 10px;
        top: 8px;
        bottom: 0;
        padding: 0;
    }
}