$Height: 170px;

#popup-root {
    .popup-overlay {
        .CustomFormat-content {
            width: 90%;
            padding: 10px 30px !important;
            overflow: scroll;
            max-height: 100%;

            .Modal {
                .content {
                    .GenerateCustomFormat {
                        // height: $Height;
                        @include hr;

                        .dropzone {
                            flex: 1;
                            // height: $Height - 20px;

                            .GridItem {
                                // width: 220px;
                                min-height: 65px;
                                position: relative;
                                min-width: 230px;
                                max-width: 230px;
                                padding: 10px;

                                .grid-item {
                                    // padding: 10px;

                                    .grid-item-content {
                                        width: 100%;
                                        height: 100%;
                                        background-color: $darkNavy;
                                        border-color: $darkNavy;
                                        display: flex;
                                        justify-content: space-evenly;
                                        color: $white;
                                        align-items: center;
                                        border-radius: 0.25rem;
                                        padding: 10px 0;

                                        .RemoveElement {
                                            color: $white;
                                            right: 22px;
                                            top: 8px;

                                            &:hover {
                                                color: $HoverDarken;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .DelimiterSection {
                        @include hr;
                        padding-bottom: 1rem;
                    }

                    .FinalCustomFormat {
                        @include hr;
                        padding-bottom: 1rem;
                    }
                }
            }
        }
    }
}