.pagination {
    .active {
        .page-link {
            background-color: $darkNavy;
            border-color: $darkNavy;
        }
    }

    .page-link {
        color: $darkNavy;
    }
}