.CountdownWrapper {
    div {
        color: $textColour !important;
    }

    >div {
        &:nth-of-type(1) {
            margin: auto;
        }
    }
}