.IconBtn {
    .btn {
        margin-right: 20px;
        float: left;

        svg {
            display: block;
            float: left;
            position: relative;
            top: 4px;
            margin-right: 5px;
        }
    }
}