form {
    .FormGroup {
        margin-bottom: 1rem;

        // .Dropdown {
        //     &:after {
        //         content: "";
        //         position: absolute;
        //         right: 8px;
        //         top: 18px;
        //         border-top: 0.3em solid;
        //         border-right: 0.3em solid transparent;
        //         border-bottom: 0;
        //         border-left: 0.3em solid transparent;
        //     }

        //     &[disabled] {
        //         &:after {
        //             display: none;
        //         }
        //     }
        // }

        .form-control {
            border-radius: 0;
            border: 2px solid $midNavy;
            max-width: 620px;
            min-width: 100px;
            color: $textColour;
            padding: 0.375rem 1.8rem 0.375rem 0.75rem;

            &:focus {
                box-shadow: none;
            }

            &:disabled {
                color: $textColour;
                // background-color: $grey;
                border: 0px;
                background: none;
                opacity: 1;
                -webkit-box-shadow: 0 0 0 30px $grey inset;
                box-shadow: 0 0 0 30px $grey inset;
            }

            &.EmptyButton {
                background-color: $white;
                border-radius: 0;
                border: 2px solid $midNavy;
                min-height: 40px;

                &:disabled {
                    border: 0px;
                    background: none;
                }

                &.disabled {
                    border: 0px;
                    background: none;
                }
            }
        }

        .form-select {
            border-radius: 0;
            border: 2px solid $midNavy;
            max-width: 620px;
            min-width: 100px;
            color: $textColour;
            padding: 0.375rem 1.4rem 0.375rem 0.75rem;
            background-position: right 0.4rem center;

            &:focus {
                box-shadow: none;
            }

            &:disabled {
                color: $textColour;
                // background-color: $grey;
                border: 0px;
                background: none;
                opacity: 1;
                -webkit-box-shadow: 0 0 0 30px $grey inset !important;
                box-shadow: 0 0 0 30px $grey inset !important;
            }


        }

        .btnToggle[disabled] {
            color: $textColour;
            opacity: 0.5;
            cursor: auto;
        }

        .btnToggle {
            color: $midNavy;
            font-size: 2rem;
            cursor: pointer;

            svg {
                vertical-align: top;
            }
        }

        .CheckSection {
            .CheckSectionContent {
                .FormGroup {
                    margin-bottom: 0;

                    .form-label {
                        text-align: left;
                        padding-top: 0;
                    }
                }
            }
        }

        .react-datepicker-wrapper {
            // width: 115px;

            input {
                cursor: pointer;

                &:before {
                    top: 0px;
                    left: 0;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: tranparent;
                    content: "";

                    display: block;
                    z-index: 999;
                }
            }
        }

        .form-control-plaintext {
            padding: 0.375rem 0.75rem;
            color: $textColour;

            // &:focus {
            // outline: none;
            // }
        }

        .btn-group {
            &.btn-group-toggle {
                input {
                    position: absolute;
                    clip: rect(0, 0, 0, 0);
                }
            }
        }
    }

    .Values {
        @import "ValueInput";
    }

    .input-group> {

        .custom-file,
        .custom-select,
        .form-control,
        .form-control-plaintext {
            position: relative;
            -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
            width: 1%;
            min-width: 0;
            margin-bottom: 0;

            // &:focus-visible {
            //     outline: none;
            // }
        }

        .custom-select {
            height: calc(1.5em + .75rem + 2px);
            padding: .375rem 1.75rem .375rem .75rem;
            vertical-align: middle;
            -webkit-appearance: none;
            appearance: none;
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: right .75rem center;
            background-size: 16px 12px;
            border-radius: 0;
            border: 2px solid $midNavy;

            &:focus {
                box-shadow: none;
            }

            option {
                border-radius: 0;
                border: 2px solid $midNavy;
            }

            &:disabled {
                color: $textColour;
                // background-color: $grey;
                border: 0px;
                background: none;
                opacity: 1;
            }
        }
    }
}