.btn {
    transition: $HoverTransition;

    &:hover {
        background-color: $midNavy;
        border-color: $midNavy;
        box-shadow: none !important;
    }

    &:focus-visible {
        background-color: $midNavy;
        border-color: $midNavy;
        box-shadow: none !important;
    }
}

.btn-default {
    color: $textColour;
    background: none;
    border-color: $midNavy;
    border-radius: 0;
    border-width: 2px;
}

.btn-primary {
    color: $textColour;
    background: none;
    border-color: $midNavy;
    border-radius: 0;
    border-width: 2px;

    &:focus {
        box-shadow: none !important;
    }

}

.btn-danger {
    background-color: $red;
    border-color: $red;

    &:hover {
        background-color: $DangerHoverDarken;
        border-color: $DangerHoverDarken;
        box-shadow: none !important;
    }

}

.btn-primary:not(:disabled):not(.disabled) {

    &:focus,
    &:active,
    &.active {
        background-color: $darkNavy;
        border-color: $darkNavy;
        color: $white;
    }
}

.btn-primary:disabled {
    background-color: $darkNavy;
    border-color: $darkNavy;
    color: $white;
}

.btn-check+.btn:hover {
    // background-color: $darkNavy !important;
    border-color: $darkNavy !important;
    color: $darkNavy;
    background: none;
}

.RadioGroup {
    display: flex;
    flex-direction: column;

    .btn-primary:not(:disabled):not(.disabled) {

        &:focus,
        &:active,
        &.active {
            // background-color: $darkNavy;
            // border-color: $darkNavy;
            // color: $white;
            color: $darkNavy;
            background: none;
        }
    }

    .btn-check {
        &[disabled] {
            + {
                .btn-primary {
                    border-color: transparent;
                    opacity: 1;
                    color: $darkNavy;
                    background: none;
                }
            }
        }



        &:focus {
            + {
                .btn-primary {
                    box-shadow: none !important;
                }
            }
        }

        &:checked:not(:disabled) {
            + {
                .btn-primary {
                    background-color: $darkNavy;
                    color: $darkNavy;
                    background: none;
                    border-color: $darkNavy;
                }
            }
        }
    }
}

.show>.btn-primary.dropdown-toggle {
    background-color: $darkNavy;
    border-color: $darkNavy;
}

// .btn-success {
// 	background-color: $darkNavy;
// 	border-color: $darkNavy;

// 	&:hover {
// 		background-color: $HoverDarken;
// 		border-color: $HoverDarken;
// 	}

// 	&:focus {
// 		box-shadow: 0 0 0 0.2rem $lightNavy;
// 	}
// }

