#popup-root {
    .popup-overlay {
        background: none;
        @include FadeInAnimation;
        overflow: hidden;
        z-index: 1001 !important;

        .popup-content {
            padding: 35px;
            text-align: left;
            background: $white;
            border-radius: 10px;
            border: 4px solid $darkNavy;
            height: 86%;


            &.UserPreferencesPopup-content {
                width: 30%;
                height: auto;
            }


            .Modal {
                overflow-y: auto;
                overflow-x: hidden;
                height: 100%;
                width: 100%;

                .close {
                    @include Close;
                }

                .Title {
                    @include hr;
                    margin-top: 0;
                }

                .content {
                    position: relative;

                    .IconBtn {
                        margin-top: 20px;

                        .invalid-tooltip {
                            top: -10%;
                            position: relative;
                        }
                    }
                }
            }
        }
    }
}