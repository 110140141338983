/**
 * @section App Global Styles
 */

html {
	height: 100%;
}

body {
	@include fontStyle;
	color: $textColour;
	min-height: 100%;
	margin: 0;
	// padding: $headerHeight 0 0 $sidebarWidth;
	// overflow: hidden;

	.tooltip {
		.tooltip-inner {
			max-width: fit-content;
		}
	}
}

.backgroundContainer {
	// background-color: $lightNavy;
	min-height: 40px;
	min-width: 40px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

select {
	&.form-control {
		font-size: 16px;
	}
}

textarea {
	&.form-control {
		font-size: 16px;
	}
}

input {
	&.form-control {
		font-size: 16px;
	}
}

input[type=file] {
	width: 100%;
}

.filter-label {
	display: block
}

.form-label {
	text-align: right;
}

.noPadding {
	padding: 0;
}

.App {
	margin-top: 15px;
}

h1 {
	font-size: 2rem;
}

h2 {
	font-size: 1.75rem;
}

h3 {
	font-size: 1.5rem;
}

h4 {
	font-size: 1.25rem;
}

h5 {
	font-size: 1rem;
}

h6 {
	font-size: 0.75rem;
}

a {
	color: $darkNavy;
	transition: $HoverTransition;

	&:hover {
		color: $HoverDarken;
	}
}

// :focus {
// outline: none;
// }

.tick {
	color: $darkNavy;
}

.cross {
	color: $red;
}

.spinner {
	svg {
		animation: rotation 2s infinite linear;
	}

	@keyframes rotation {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(359deg);
		}
	}
}

// .tooltip {
// 	.tooltip-inner {
// 		background-color: $darkNavy;
// 	}

// 	>.tooltip-arrow {
// 		border-top-color: $darkNavy;
// 	}

// }

.row>* {
	position: relative;
}

.invalid-tooltip {
	display: block;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	background-color: $red;
	top: 130%;

	&.typeError {
		top: 0;
		max-width: 95%;
	}
}

// input {
// 	&:focus {
// outline: none;
// 	}
// }

.LoaderContainer {
	div {
		&:first-child {
			z-index: 2000 !important;
		}
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px $white inset !important;
	box-shadow: 0 0 0 30px $white inset !important;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}