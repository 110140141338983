/**
 * @section Colour Varibles
 */

$white:#fff;
$darkNavy: #2e313f;
$midNavy: #3f415e;
$lightNavy: #494b66;
$red: #bc3636;
$textColour: #333333;
$grey: #F5F5F5;
$darkGrey: #999999;
$borderGrey: #ced4da;