/**
 * @section Login Page Styles
 */

@media all and (min-width: 480px) {
	.Login {
		padding: 25px 0 15% 0;

		form {
			max-width: 700px;
			margin: 0 auto;
		}
	}
}