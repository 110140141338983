.CloseButton {

    position: absolute;
    right: 15px;
    top: 8px;
    cursor: pointer;
    float: right;
    font-size: 1.5rem;
    width: initial;

    &.close {
        opacity: 1;

        &:hover {
            color: darken($red, 10%);
            opacity: 1 !important;
        }
    }
}

.invalid-tooltip {
    .CloseButton {
        position: relative;
        right: -5px;
        top: -10px;

        &.close {
            &:hover {
                color: $midNavy;
            }
        }
    }
}