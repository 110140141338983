.contentContainer {
    .content {
        .NewReceiptList {
            .TooltipActionButton {
                position: absolute;
                top: 20px;
                z-index: 1000;
                margin: 0;
            }
        }
    }
}