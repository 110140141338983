/**
 * @section Content Container Styles
 */

.contentContainer {
    background-color: $white;
    // border-radius: 25px 0 0 0;
    // border-top: 4px solid $darkNavy;
    // border-left: 4px solid $darkNavy;
    // border-right: none;
    // border-bottom: none;
    // position: fixed;
    // width: $contentWidth;
    // width: 100%;
    // padding-bottom: 100px;
    // height: 93%;
    // overflow: auto;

    .content {
        // margin-top: 1rem;
        // background-color: $white;
        margin-bottom: 5%;
        background-color: $grey;
        // margin-left: $sidebarWidth - 1%;
        // margin-left: 98px;

        // @media all and (max-width: 1400px) {
        //     margin-left: auto;
        // }
        .ContentBackground {
            padding-top: 2rem;
            padding-bottom: 1rem;
        }

        h1 {
            // color: $darkNavy;
            // font-weight: bolder;
            position: absolute !important;
            top: -9999px !important;
            left: -9999px !important;
        }

        .center {
            text-align: center;
        }

        .left {
            text-align: left;
        }

        .floatLeft {
            text-align: left;
            float: left;
            margin-right: 20px;
            margin-top: 10px;
        }

        .right {
            text-align: right;
        }

        @import "TooltipActionButton";

        .EditBtn {
            margin: 0;
        }

        .detailsContent {
            margin-top: 1rem;
            margin-bottom: 1rem;
            background: $grey;
            // border-radius: 25px;
            padding: 20px;
            position: relative;
        }

        .Infobox {
            background: $white;
        }

        .ErrorList {
            background: rgba($red, 0.4);
        }

        .Link {
            color: $darkNavy;
            text-decoration: none;
            background-color: transparent;
            cursor: pointer;

            &:hover {
                color: $HoverDarken;
            }
        }

        .custom-control-input {
            &:checked {
                &~.custom-control-label {
                    &::before {
                        border-color: $darkNavy;
                        background-color: $darkNavy;

                    }
                }
            }
        }

        .react-table {
            .Status {
                border: none;
                overflow: hidden;
                // height: 95px;
                // box-shadow: none;
                background-color: transparent;

                option {

                    &:checked {
                        border: 1px solid $darkNavy;
                        background-color: $darkNavy !important;
                        color: $darkNavy !important;
                        border-radius: 0.25rem;
                    }

                    // &:not(:checked) {
                    //     // background-color: #FFF;
                    //     // border: none;
                    // }
                }
            }
        }
    }
}