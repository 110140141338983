/**
 * @section Tabs Styles
 */

.Tabs {

    // height: 100%;
    // position: fixed;
    // z-index: 2;
    // top: 0;
    // left: 0;
    // width: $sidebarWidth;
    margin-top: 23px;
    font-size: 1.38rem;


    .navbar {
        display: block;
        padding-top: 0rem;
        padding-bottom: 0rem;

        .nav-tabs {
            border-bottom: none;

            .nav-link {
                border: none;
                border-radius: 0;
                color: $white;

                &.active {
                    background-color: $grey;
                    color: $textColour;
                    font-weight: bolder;
                }
            }

            .nav-item {
                background-color: $midNavy;

            }
        }
    }

    // h1 {
    //     color: $darkGrey;
    //     font-weight: bolder;
    // }

    // h3 {
    //     color: $darkGrey;
    //     font-weight: bolder;
    //     margin-top: 0.5rem;
    // }

    // .navbar {
    //     padding: 0;
    // }

    // svg {
    //     color: $darkNavy;
    //     margin-top: 0.7rem;
    //     min-width: 16px;
    // }



    // .navbar-light {
    //     .navbar-nav {
    //         .show>.nav-link {
    //             color: rgba(0, 0, 0, 0.5);
    //         }
    //     }

    //     .nav-link.active {
    //         color: rgba(0, 0, 0, 0.9);
    //     }
    // }
}