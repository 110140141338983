.FormSave {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;

    .row {
        &>div {
            background: $grey;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    .invalid-tooltip-container {
        position: relative;

        .invalid-tooltip {
            top: initial;
            bottom: 0;
            margin-bottom: 0.5rem;
        }
    }
}