#ValueInput {
    .btnAdd {
        position: relative;
        min-height: 38px;

        .btn {
            width: 38px;
            height: 38px;
            position: absolute;
            top: 0;
            left: 0;

            svg {
                position: relative;
                right: 7px;
                bottom: 2px;
                width: 1.8rem;
                height: 1.8rem;
            }
        }
    }



    .FormGroup {
        margin-bottom: 0;

        .ValueInputElement {
            margin-bottom: 1rem;
        }
    }
}