#popup-root {
    .popup-overlay {
        @include FadeInAnimation;

        .RuntimeFilter-content {
            padding: 30px 0;

            .Modal {
                .Title {
                    padding: 0 30px;
                    border-bottom: 0;
                }

                .content {
                    .react-table {
                        // min-height: 540px;
                        margin-bottom: 1rem;

                        .table {
                            thead {
                                tr {
                                    th {
                                        &:first-child {
                                            border-top-left-radius: 0;
                                            border-left: none;
                                        }

                                        &:last-child {
                                            border-top-right-radius: 0;
                                            border-right: none;
                                        }
                                    }
                                }
                            }

                            tbody {
                                tr {
                                    td {
                                        cursor: pointer;

                                        &:first-child {
                                            border-left: none;
                                        }

                                        &:last-child {
                                            border-right: none;
                                        }
                                    }

                                    &:last-child {
                                        td {
                                            &:first-child {
                                                border-bottom-left-radius: 0;
                                                border-left: none;
                                            }

                                            &:last-child {
                                                border-bottom-right-radius: 0;
                                                border-right: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .react-table-pagination {
                        padding: 0 30px;
                    }

                    .PaginationArea {
                        padding: 0 30px;

                        .col {
                            &.right {
                                text-align: right;
                            }

                            .btn {
                                margin: 0 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}