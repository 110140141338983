.react-table {


    .table {
        // background: $white;
        border-collapse: separate;
        border-spacing: 0;
        // border-radius: 25px;
        border: 0;
        // margin-top: 1rem;

        thead {
            tr {
                th {
                    position: sticky;
                    top: -1px;
                    // background: $white;
                    border: 0px;
                    // padding: 0.75rem;
                    background: $lightNavy;
                    // background: -moz-linear-gradient(0deg, $white 0%, $lightNavy 35%);
                    // background: -webkit-linear-gradient(0deg, $white 0%, $lightNavy 35%);
                    // background: linear-gradient(0deg, $white 0%, $lightNavy 35%);
                    // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fff", endColorstr="#45b419", GradientType=1);
                    // border-top: 4px solid $darkNavy;
                    padding-bottom: 1rem;
                    color: $white;

                    &.ButtonCol {
                        width: 10%;
                    }

                    &.smlButtonCol {
                        width: 5%;
                    }

                    &.mdCol {
                        width: 15%;
                    }

                    // &:first-child {
                    //     border-top-left-radius: 25px;
                    //     border-left: 4px solid $darkNavy;
                    // }

                    // &:last-child {
                    //     border-top-right-radius: 25px;
                    //     border-right: 4px solid $darkNavy;
                    // }

                    &[data-row-selection] {
                        width: 50px;
                    }
                }

                &.isPinned {
                    th {
                        border-top: 0;

                        // &:first-child {
                        //     border-top-left-radius: 0;
                        // }

                        // &:last-child {
                        //     border-top-right-radius: 0;
                        // }
                    }
                }

            }
        }

        tbody {
            tr {
                background: $white;
                transition: $HoverTransition;

                &:hover {
                    background: $grey !important;
                }

                &:not(.RowElement) {
                    &:hover {
                        cursor: pointer;
                    }
                }

                td {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 300px;

                    .TransactionLineRow {
                        background: $borderGrey;
                        padding: 10px;
                        margin: -5px;
                    }

                    // padding: 0.75rem;

                    // &.ButtonCol {
                    //     width: 10%;
                    // }

                    // &:first-child {
                    //     border-left: 4px solid $darkNavy;
                    // }

                    // &:last-child {
                    //     border-right: 4px solid $darkNavy;
                    // }

                    .btnToggle[disabled] {
                        color: $textColour;
                        opacity: 0.5;
                        cursor: auto;
                    }

                    .btnToggle {
                        color: $midNavy;
                        font-size: 2rem;
                        cursor: pointer;

                        svg {
                            vertical-align: top;
                        }
                    }

                    .invalid-tooltip {
                        &.typeError {
                            top: initial;
                        }
                    }
                }

                // &:last-child {
                //     td {
                // border-bottom: 4px solid $darkNavy;

                // &:first-child {
                //     border-bottom-left-radius: 25px;
                //     border-left: 4px solid $darkNavy;
                // }

                // &:last-child {
                //     border-bottom-right-radius: 25px;
                //     border-right: 4px solid $darkNavy;
                // }
                //     }
                // }

                // &:nth-child(odd) {
                //     background: $white;
                //     transition: $HoverTransition;

                //     &:hover {
                //         background: rgba(0, 0, 0, 0.075);
                //     }


                // }

                // &:nth-child(even) {
                //     background: $grey;
                //     transition: $HoverTransition;

                //     &:hover {
                //         background: rgba(0, 0, 0, 0.075);
                //     }


                // }

                .ButtonRemove {
                    background: none;
                    border: none;
                    color: $darkNavy;
                    padding: 0;
                    vertical-align: baseline;
                    transition: $HoverTransition;

                    &:not(:disabled):not(.disabled) {

                        &:focus,
                        &:active,
                        &.active {
                            background: none;
                        }
                    }

                    &:focus {
                        box-shadow: none;
                    }

                    &:hover {
                        color: $HoverDarken;
                    }
                }

                &.RowElement {
                    td {
                        // min-width: 100px;
                        max-width: 100px;
                        overflow: initial;

                        .FormGroup {
                            margin-bottom: 0;

                            .form-control,
                            .form-select {
                                max-width: 250px;
                                width: 100%;
                                min-width: auto;

                            }
                        }

                        &.right {

                            .form-control,
                            .form-control-plaintext {
                                text-align: right;
                                padding: 0.375rem 0.75rem 0.375rem 0.75rem;
                            }
                        }
                    }
                }
            }
        }

        .parentExpand {
            color: $textColour;
            background: $borderGrey;
            --bs-table-accent-bg: $borderGrey;

            td {
                position: sticky;
                top: 46px;
                background: $borderGrey;
            }
        }

        .react-table-editing-cell {
            .alert {
                position: absolute;
                z-index: 5;
                max-width: 15%;
                padding: 0.25rem 0.5rem;
                margin-top: .1rem;
                font-size: 0.875rem;
                line-height: 1.5;
                color: $white;
                background-color: $red;
                border-radius: 0.25rem;
                white-space: pre-line;

                strong {
                    font-weight: inherit;
                }
            }
        }

        td {
            border: 0px;

            &.reset-expansion-style {
                padding: 0 10px;
                background-color: $borderGrey;
                overflow: inherit;

                &:hover {
                    background: $borderGrey;
                    --bs-table-accent-bg: $borderGrey;
                }

                .row-expansion-style {
                    padding: 0px;

                    thead {
                        tr {


                            &:first-child {
                                transition: $HoverTransition;
                            }

                            th {
                                top: 94px;
                                border: 0;

                                &:first-child {
                                    border-top-left-radius: 0;
                                }

                                &:last-child {
                                    border-top-right-radius: 0;
                                }
                            }
                        }
                    }

                    td {
                        border-bottom: 0;

                        &:first-child {
                            border-left: 0;
                            border-bottom-left-radius: 0;
                        }

                        &:last-child {
                            border-right: 0;
                            border-bottom-right-radius: 0;
                        }
                    }

                    tbody {
                        tr {
                            &:last-of-type {
                                td {
                                    &:first-child {
                                        border-bottom-left-radius: 25px;
                                    }

                                    &:last-child {
                                        border-bottom-right-radius: 25px;
                                    }
                                }
                            }
                        }
                    }

                    transition: $HoverTransition;
                }
            }
        }
    }
}

.react-table-pagination {
    .react-table-pagination-total {
        padding-left: 10px;
    }
}