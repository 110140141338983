/**
 * @section Header Styles
 */

.header {
    background-color: $lightNavy;
    // width: 100%;
    // position: fixed;
    // z-index: 1;
    // top: 0;
    min-height: $headerHeight;
    color: $white;
    font-size: 14px;

    .headerContainer {
        // width: 100% - $sidebarWidth;

        // @media (min-width: 1441px) {
        //     width: 100%;
        //     padding-right: var(--bs-gutter-x, 27.75rem);
        //     padding-left: var(--bs-gutter-x, 9.75rem);
        //     margin-right: auto;
        //     margin-left: auto;
        // }
        .row {
            .navbar-brand {
                display: contents;

                img {
                    position: relative;
                    top: 20%;
                    bottom: 20%;
                }
            }

            .welcome {
                text-align: right;
                // margin-top: 3.5%;

                @media (max-width: 425px) {
                    text-align: center;
                    margin: 5%;
                }

                span {
                    position: relative;
                    top: 50%;
                    bottom: 50%;
                }
            }

            .btn-primary {
                color: $white;
                border-color: $white;
                min-width: 86px;
                min-height: 50px;
                font-size: 14px;
                padding: 0 5px;
                margin-top: 40%;

                @media (max-width: 425px) {
                    margin: 5%;
                }

                &:hover {
                    background-color: $white;
                    border-color: $white;
                    color: $midNavy;
                }

                &:not(:disabled) {
                    &:not(.disabled) {
                        &:focus {
                            background-color: $white;
                            border-color: $white;
                            color: $midNavy;
                        }

                        &:active {
                            background-color: $white;
                            border-color: $white;
                            color: $midNavy;
                        }
                    }

                    &:not(.disabled).active {
                        background-color: $white;
                        border-color: $white;
                        color: $midNavy;
                    }
                }
            }

            div {
                &:last-of-type {
                    text-align: right;
                }
            }

            // .navbar {
            //     padding: 2% 0rem;
            //     float: right;

            //     * {
            //         box-shadow: none !important;
            //     }

            //     .navbar-nav {
            //         .welcome {
            //             display: block;
            //             padding: 0.5rem 2.4rem;
            //         }
            //     }
            // }
        }
    }
}