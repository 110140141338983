@mixin Close {
    padding: 0;
    background-color: transparent;
    border: 0;
    font-size: 1.5rem;
    line-height: 1;
    color: $darkNavy;
    cursor: pointer;
    position: absolute;
    display: block;
    right: 5px;
    top: 5px;
    opacity: 1;
    transition: $HoverTransition;

    &:hover {
        color: $HoverDarken;
    }
}