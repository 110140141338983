.TooltipActionButton {
    text-align: right;
    float: right;
    margin-left: 20px;
    margin-top: 10px;

    .btn {
        display: block;
        float: right;

        svg {
            display: block;
            float: left;
            position: relative;
            top: 4px;
            margin-right: 5px;
        }
    }
}

.noData {
    .TooltipActionButton {
        text-align: left;

        a {
            float: left;
        }
    }
}

.PlacedAboveContent {
    .TooltipActionButton {
        margin: 0;
    }
}