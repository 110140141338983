.filepond--wrapper {
    .filepond--panel-root {
        background-color: transparent;
    }

    .filepond--file {
        background: $midNavy;
        border-radius: 0;
    }

    .filepond--file-action-button {
        background-color: transparent;
        transition: $HoverTransition;    

    &:hover {
        box-shadow: none;
        background-color: $white;
        color: $midNavy;
    }
}
}