/**
 * @section Footer Styles
 */
footer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    left: 0px;
    position: fixed;
    background-color: $lightNavy;
    z-index: 999;
    bottom: 0px;
    box-shadow: none;

    margin: 0;
    border-radius: 0;

    .cookieConsent {
        align-items: baseline;
        border: 0;
        background: none;


        div {
            width: 40%;
            display: inline;
            color: $white;


            .btn {
                &:hover {
                    background-color: $white;
                    border-color: $white;
                    color: $midNavy;
                }
            }

            .btn-primary {
                color: $white;
                border-color: $white;

                &:not(:disabled) {
                    &:not(.disabled) {
                        &:focus {
                            background-color: $white;
                            border-color: $white;
                            color: $midNavy;
                        }

                        &:active {
                            background-color: $white;
                            border-color: $white;
                            color: $midNavy;
                        }
                    }

                    &:not(.disabled).active {
                        background-color: $white;
                        border-color: $white;
                        color: $midNavy;
                    }
                }
            }
        }
    }
}