.react-confirm-alert-overlay {
    background: none !important;
    z-index: 1000 !important;

    .ConfirmAlert {
        .AlertBody {
            padding: 30px;
            text-align: left;
            background: $white;
            border-radius: 10px;
            // box-shadow: 0 20px 75px $lightNavy;
            border: 4px solid $darkNavy;

            .ButtonGroup {
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;
                justify-content: flex-start;
                margin-top: 20px;

                button {
                    margin-right: 10px;
                }
            }
        }
    }
}